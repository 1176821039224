import styled from 'styled-components';

const MainContainer = styled.div`
width: 100%;
height: 100%;

display: flex;
justify-content: center;
align-items: center;
`;

export default MainContainer;
